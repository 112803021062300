import apower from "./apower";
import $ from "@apowersoft/jqueryvm";
export default class LazyLoad {
  constructor({ el, componentFactory = Function.prototype, onScroll }) {
    this.componentFactory = componentFactory;
    const selector = el;
    this.selector = selector;
    el = $(selector)[0];
    this.el = el;
    if (!$(this.el)[0]) return;
    this.elScrollTop = $(this.el).offset().top;
    $(this.el).hide();
    if (onScroll) {
      const scrollHandler = () =>
        this.onScroll(typeof onScroll === "number" ? onScroll : null);
      scrollHandler();
      window.addEventListener("scroll", scrollHandler);
      return;
    }
    if (!("IntersectionObserver" in window)) {
      this.loadComponent();
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (entries[0].intersectionRatio <= 0) return;

        observer.unobserve(el);
        this.loadComponent();
      },
      {
        rootMargin: "0px 0px 300px 0px",
      }
    );
    observer.observe(el);
  }
  async loadComponent() {
    const module = await this.componentFactory();
    if (module) {
      module.default($, apower);
      $.newComponent({
        el: this.selector,
      });
    }
    $(this.el).fadeIn();
  }
  onScroll(distance) {
    if (this.loadedOnScroll) return;
    const scroller =
      document.scrollingElement || document.documentElement || document.body;
    const scrollTop = scroller.scrollTop;
    const THRESHOLD = distance
      ? this.elScrollTop - distance - window.innerHeight
      : 400;
    if (scrollTop > THRESHOLD) {
      this.loadComponent();
      this.loadedOnScroll = true;
    }
  }
}

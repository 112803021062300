//for header
import "../styles/components/header.css";
import "../styles/components/drop.css";
import registerIcon from "./icon";
import registerBaiduSearch from "./baiduSearch";
export default function registerHeader($, apower) {
  //header visibility toggle when scrolling
  registerIcon($, apower);
  apower.scroller = $.registerOnce(document, {
    downFns: [],

    upFns: [],

    lastScrollTop: 0,

    init: function () {
      this.lastScrollTop = $("html").scrollTop() || $("body").scrollTop();
      this.el.$on("scroll", this.onDomScroll, this);

      setTimeout(
        function () {
          this.trigger("scroll", this.lastScrollTop);

          if (this.lastScrollTop > 100) {
            this.trigger("down", this.lastScrollTop);
          }
        }.bind(this),
        0
      );

      this.onDomScroll();
    },

    onDomScroll: function (event) {
      var newScrollTop = $("html").scrollTop() || $("body").scrollTop();
      var winWidth = $(window).width();
      var direction = newScrollTop < this.lastScrollTop ? "up" : "down";

      if (direction === "up") {
        this.trigger("up", newScrollTop);
      } else if (newScrollTop >= 100) {
        this.trigger("down", newScrollTop);
      }

      this.trigger("scroll", newScrollTop, direction);

      this.lastScrollTop = newScrollTop;
    },
  });

  window._header = $.registerOnce(".header", {
    ref: {
      nav: ".nav",
      btns: ".nav li.has-sub, .search-btn",
      navs: ".subnav, .nav-popup, .search",
      menu: ".menu",
      trangle: ".subnav .trangle,.nav-popup .trangle",
      searchBlank: ".search-blank",
    },

    isArticle: apower.pageType === "article" || apower.pageType === "category",

    isAbout: apower.pageType === "about",

    headerStatic: false,

    mobileStyleWhenWidth: 1024,

    init: function () {
      this.btns.filter(".has-sub:not(.support-li)").each(function (index, btn) {
        $.newComponent("icon", { type: "down", target: btn });
      });

      this.btns.filter(".search-btn").each(function (index, btn) {
        $.newComponent("icon", { type: "search-thick", target: btn });
      });

      this.initSearch();
      this.initMobileIcon();
      //搜索框加 图标
      // $.newComponent('icon', {type: 'search-thick', target: this.searchBlank})

      // stroe 按钮 图标svg
      var storeEl = this.el.find(".nav li.store a");
      if (storeEl.length) {
        $.newComponent("icon", { type: "store", target: storeEl });
      }

      this.headerStatic = this.el.css("position") === "static";

      if (!this.headerStatic) {
        this.el.stopScroll();
      }

      this.el.find(".solution dt").on("click", this.onClickDtTrangle, this);

      this.initScroller();

      $(window).$on("resize", this.onResize, this);

      //will use a new layout for header
      if (this.isArticle && window.innerWidth > this.mobileStyleWhenWidth) {
        this.initArticleHeader();
      } else {
        this.btns
          .filter(".has-sub")
          .$on("mouseenter", this.onEnterNavBtn, this);

        $.onToggleWidth(
          this.mobileStyleWhenWidth,
          //mobile
          function () {
            this.btns.$off("mouseenter", this.onEnterNavBtn, this);
            this.btns.$on("click", this.onClickNavBtn, this);
            this.nav.stopScroll();
            this.navs.stopScroll();
            this.trangle.$on("click", this.onClickTrangle, this);
          },
          //pc
          function () {
            this.nav.show();
            this.btns.$on("mouseenter", this.onEnterNavBtn, this);
            this.btns.$off("click", this.onClickNavBtn, this);
            this.nav.restoreScroll();
            this.navs.restoreScroll();
          },
          this
        );
      }

      this.menu.$on("click", this.onClickMenu, this);
    },

    initMobileIcon() {
      this.el.find(".solution dt").append('<div class="dt-trangle"></div>');
      this.el.find(".solution dt").addClass("active");
    },

    onClickDtTrangle(e) {
      let el = $(e.currentTarget);
      let dd = el.siblings("dd");

      if (el.is(".active")) {
        dd.hide();
        el.removeClass("active");
      } else {
        dd.show();
        el.addClass("active");
      }
    },

    initSearch: function () {
      var btn = this.el.find(".search-btn"),
        input = this.el.find("form input[type=text]"),
        field = this.el.find(".input-field"),
        submit = this.el.find(".search-btn input[type=submit]"),
        closeBtn = field.find(".search-close"),
        form = this.el.find("form");
      btn.on("click", function (e) {
        if (!field.is(".active") || !input.val()) {
          e.preventDefault();
        }
      });

      btn.on("click", function (e) {
        field.addClass("active");
        submit.addClass("active");
        closeBtn.delay(400).fadeIn(200);
        field.stop().fadeIn(400, function () {
          input.focus();
        });
      });

      closeBtn.on("click", function (e) {
        field.removeClass("active");
        submit.removeClass("active");
        closeBtn.fadeOut(200);
        input.val("");
        field.stop().fadeOut(400, function () {
          field.show();
        });
      });

      submit.on("click", function (e) {
        form.submit();
      });
    },

    initScroller: function () {
      if (
        apower.pageType !== "product" ||
        $(window).width() <= this.mobileStyleWhenWidth
      ) {
        apower.scroller.on("up", this.onScrollUp, this);
        apower.scroller.on("down", this.onScrollDown, this);
        apower.scroller.on("scroll", this.onScroll, this);
      }
    },

    onResize: function () {
      if (
        this.nav.attr("hidden") &&
        window.innerWidth > this.mobileStyleWhenWidth
      ) {
        this.nav.stop().fadeIn(300);
      }
    },

    onEnterNavBtn: function (e) {
      if (this.lastNav) {
        this.lastNav.hide();
      }

      var btn = $(e.target).closest("li"),
        nav = this.navs.eq(this.btns.indexOf(btn));

      btn.addClass("active");

      nav.stop().fadeIn(this.lastNav ? 0 : 200);
      nav.alignTo(btn, "b", [0, 6]);
      nav.find('input[type="text"]').focus();

      //we need to make sure nav hide when mouse leave btn and nav for 200ms
      $.onceAllLeave(
        [btn, nav],
        200,
        function () {
          btn.removeClass("active");
          nav.stop().fadeOut(200);

          //avoid reset other lastNav when multiple enter triggers
          if (this.lastNav === nav) {
            this.lastNav = null;
          }
        },
        this
      );

      this.lastNav = nav;
    },

    onClickNavBtn: function (e) {
      e.preventDefault();

      if (this.lastNavBtn) {
        this.lastNavBtn.removeClass("active");
      }

      if (this.lastNav) {
        this.lastNav.hide();
      }

      var btn = $(e.target).closest("li"),
        nav = this.navs.eq(this.btns.indexOf(btn));

      if (window.innerWidth > this.mobileStyleWhenWidth) {
        this.nav.hide();
        btn.addClass("active");
      }

      if (window.innerWidth <= this.mobileStyleWhenWidth) {
        btn.after(nav);
        btn.siblings().removeClass("actived");

        if (btn.is(".actived")) {
          nav.hide();
          btn.removeClass("actived");
        } else {
          nav.show();
          btn.addClass("actived");
        }
      }

      if (window.innerWidth > this.mobileStyleWhenWidth) {
        nav.show();
      }
      nav.find('input[type="text"]').focus();

      this.lastNavBtn = btn;
      this.lastNav = nav;

      $(".mobile-review").hide();
    },

    onClickTrangle: function (e) {
      this.lastNav.hide();
      this.nav.stop().show();
    },

    onClickMenu: function () {
      if (this.menu.hasClass("active")) {
        this.menu.removeClass("active");
        this.nav.fadeOut(300);

        if (this.lastNavBtn) {
          this.lastNavBtn.removeClass("active");
        }

        if (this.lastNav) {
          this.lastNav.fadeOut(300);
        }

        if (window.innerWidth <= this.mobileStyleWhenWidth) {
          this.btns.removeClass("actived");
        }

        this.lastNavBtn = this.lastNav = null;
      } else {
        this.nav.stop().fadeIn(300);
        this.menu.addClass("active");
        $(".mobile-review").hide();
      }
    },

    onScrollUp: function (scrollTop) {
      if (!this.scrollLocked) {
        this.el.removeClass("slide-up");

        if (scrollTop <= 50) {
          this.el.css("transition", "none");

          setTimeout(
            function () {
              this.el.css("transition", "");
            }.bind(this),
            0
          );
        }
      }

      if (scrollTop != 0) {
        // 不在顶部
        this.el.addClass("not-top");
      } else {
        this.el.removeClass("not-top");
      }
    },

    onScrollDown: function () {
      if (!this.scrollLocked && !this.headerStatic) {
        this.el.addClass("slide-up");
      }
    },

    //当页面滚出了第一屏或者滚回第一屏的时候, 会切换 class: scrolled-out-main
    onScroll: function (scrollTop) {
      if (scrollTop > $(window).height()) {
        this.el.addClass("scrolled-out-main");
      } else {
        this.el.removeClass("scrolled-out-main");
      }
    },

    //css in article.css
    initArticleHeader: function () {
      var productEl = this.nav.find(".product");
      productEl.$on("mouseenter", this.onEnterProductBtn, this);

      // this.initArticleWin()
      // this.win.find('.win-close').$on('click', this.onClickArticleMenu, this)
      // this.btns.$on('mouseenter', this.onEnterArticleNavBtn, this)
      // this.menu.$on('click', this.onClickArticleMenu, this)

      // this.initArticleSearch()

      window.onhashchange = this.onHashChange.bind(this);

      if (this.hasHashSelector()) {
        this.onScrollDown();
      }
    },
    hasHashSelector() {
      if (!location.hash) {
        return false;
      }
      try {
        $(location.hash);
        return true;
      } catch (err) {
        return false;
      }
    },

    onHashChange: function () {
      this.onScrollDown();

      this.scrollLocked = true;
      setTimeout(
        function () {
          this.scrollLocked = false;
        }.bind(this),
        50
      );
    },

    onEnterProductBtn: function (e) {
      var el = $(e.target).closest(".product");
      var winSubnav = this.el.find(".solution");
      // 取消 mac的特殊显示
      var macSubnav = this.el.find(".solution"); //.mac
      var nav;

      el.addClass("active");

      if ($.env.isWin) {
        winSubnav.show();
        nav = winSubnav;
      } else if ($.env.isMac) {
        macSubnav.show();
        nav = macSubnav;
      }

      $.onceAllLeave(
        [el, nav],
        200,
        function () {
          el.removeClass("active");
          nav.stop().fadeOut(200);
        },
        this
      );
    },

    initArticleWin: function () {
      this.el.find(".nav li").removeClass("has-sub").eq(0).addClass("active");
      this.el.find(".search").appendTo(this.el.find(".wrapper"));
      this.el.find(".search-btn").remove();
      this.el.find(".search").prepend('<div class="search-close"></div>');
      this.el.find(".trangle").remove();

      this.win = $(
        '<div class="navwin">' +
          '<div class="trangle"></div>' +
          '<div class="win-close"></div>' +
          '<div class="navwin-tabs">' +
          '<div class="navwin-scroller"></div>' +
          "</div>" +
          "</div>"
      );

      this.el.after(this.win);
      this.win.find(".navwin-tabs").before(this.nav);
      this.el
        .find(".nav-popup")
        .not(".search")
        .removeClass("nav-popup")
        .addClass("subnav");

      var scroller = this.win.find(".navwin-scroller");
      var storeNav = $(
        '<nav class="subnav store-nav"><ul><li></li></ul></nav>'
      );

      storeNav.find("li").append(this.win.find('a[href="/store/"]').clone());
      scroller.append(this.el.find(".subnav")).append(storeNav);

      this.win.stopScroll();

      this.scroller = scroller;
      this.btns = this.win.find(".nav li");
      this.navs = this.scroller.find("> nav");

      this.btns.eq(0).addClass("active");
      this.navs.eq(0).addClass("active");
    },

    onClickArticleMenu: function (e) {
      if (this.menu.hasClass("active")) {
        this.menu.removeClass("active");
        this.win.fadeOut(300);

        $(document).$off("mousedown", this.onDomMouseDown, this);
        $(window).$off("resize", this.alignArticleMenu, this);
      } else {
        this.menu.addClass("active");
        this.win.fadeIn(300);
        this.alignArticleMenu();

        $(document).$on("mousedown", this.onDomMouseDown, this);
        $(window).$on("resize", this.alignArticleMenu, this);
      }
    },

    alignArticleMenu: function () {
      this.win.alignTo(this.menu, "br");
    },

    onDomMouseDown: function (e) {
      if (
        !$(e.target).closest(this.menu).length &&
        !$(e.target).closest(this.win).length
      ) {
        this.win.fadeOut(300);
        this.menu.removeClass("active");

        $(document).$off("mousedown", this.onDomMouseDown, this);
        $(window).$off("resize", this.alignArticleMenu, this);
      }
    },

    onEnterArticleNavBtn: function (e) {
      var btn = $(e.target).closest("li");
      var index = btn.index();
      var nav = this.navs.eq(index);

      if (nav.length) {
        this.btns.removeClass("active");
        btn.addClass("active");
        this.navs.removeClass("active");
        nav.addClass("active");
      }
    },

    initArticleSearch: function () {
      var searchEl = this.el.find(".search"),
        btn = searchEl.find("input[type=submit]"),
        field = searchEl.find("input[type=text]"),
        closeEl = searchEl.find(".search-close");

      btn.$on("click", function (e) {
        if (!searchEl.is(".active") || !field.val()) {
          e.preventDefault();
        }
      });

      btn.$on("click", function (e) {
        searchEl.addClass("active");
        closeEl.stop().fadeIn(300);
        field.focus();

        if (field.val()) {
          btn.addClass("valid");
        } else {
          btn.removeClass("valid");
        }
      });

      btn.addClass("valid");

      closeEl.$on("click", function (e) {
        searchEl.removeClass("active");
        closeEl.stop().fadeOut(300);
        btn.addClass("valid");
      });
    },
  });
  registerBaiduSearch($, apower);
}

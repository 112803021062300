export function callOnInteractive(callback) {
  const events = "scroll mousedown touchstart mousemove";
  let locked = false;
  function interactiveHandler(e) {
    if (locked) return;
    locked = true;
    callback();
    events.split(" ").forEach(function (eventName) {
      $(window).off(eventName, interactiveHandler);
    });
  }
  $(window).on(events, interactiveHandler);
}

export default function registerIcon($, apower) {
  //icon
  $.registerComponent("icon", {
    SYMBOLS: {},

    template: `<svg class="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :class="'icon-' + (type || 'unknown')"></svg>`,

    type: "",

    onReady() {
      this.watch("type", this.onTypeChange, true);
    },

    onTypeChange(type) {
      this.el.empty();

      let code = this.SYMBOLS[type];
      let el = this.el[0];

      if (code) {
        let m = code.matches(/<svg viewBox="(.+?)">([\s\S]+?)<\/svg>/);
        let viewBox = m[1];
        let innerHTML = m[2];
        let viewBoxArray = viewBox.split(" ");
        let width = viewBoxArray[2];
        let height = viewBoxArray[3];

        el.setAttribute("viewBox", viewBox);
        el.setAttribute("width", width + "px");
        el.setAttribute("height", height + "px");

        let dummy = document.createElement("div");
        dummy.innerHTML = "<svg>" + innerHTML + "</svg>";
        let svgChildNodes = Array.from(dummy.childNodes[0].childNodes);

        for (let i = 0, len = svgChildNodes.length; i < len; i++) {
          el.appendChild(svgChildNodes[i]);
        }
      } else {
        el.setAttribute("viewBox", "0 0 20 20");
        el.setAttribute("width", "20px");
        el.setAttribute("height", "20px");
      }
    },
  });

  $.getComponent("icon").extendSymbols = function (symbols) {
    $.extend($.getComponent("icon").prototype.SYMBOLS, symbols);
  };

  $.getComponent("icon").extendSymbols({
    "youtan-account":
      '\
		<svg viewBox="0 0 16.64 19.22">\
			<path style="fill:currentColor;opacity:1;" class="cls-1" d="M1392.36,671.08c-.58,0-1.23,0-1.94,0-8,0-8.32-2.22-8.32-2.22a7.31,7.31,0,0,1,3.86-6.45l.13-.09a5.31,5.31,0,0,1,3.33-9.46,11.78,11.78,0,0,1,2.94.37,5.31,5.31,0,0,0-1.4,9.09l-.13.09a7.31,7.31,0,0,0-3.86,6.45S1386.42,670.75,1392.36,671.08Z" transform="translate(-1381.1 -651.91)"/>\
			<circle style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;" class="cls-2" cx="8.32" cy="6.32" r="5.32"/>\
			<path style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;" class="cls-2" d="M1395.19,664.42a7.29,7.29,0,0,1,1.54,4.5" transform="translate(-1381.1 -651.91)"/>\
			<path style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;;" class="cls-2" d="M1382.1,668.91a7.32,7.32,0,0,1,3.86-6.45" transform="translate(-1381.1 -651.91)"/>\
			<path style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;opacity:0.1;" class="cls-3" d="M1386.59,657.66a2.4,2.4,0,0,1,1.93-2" transform="translate(-1381.1 -651.91)"/>\
		</svg>\
	',

    video:
      '\
		<svg viewBox="0 0 20.35 15.25">\
			<defs><style>.cls-1,.cls-3{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;}.cls-1{stroke-width:2px;}.cls-2{fill:currentColor;opacity:0.1;}</style></defs>\
			<title>矢量智能对象1</title>\
			<path class="cls-1" d="M1268.58,657.48v-1.72a2.32,2.32,0,0,0-2.32-2.32h-8.64a2.32,2.32,0,0,0-2.32,2.31l0,8.61a2.32,2.32,0,0,0,2.32,2.32h8.65a2.32,2.32,0,0,0,2.32-2.31v-1.79l5.06,2.35v-7.48l-2.21.76" transform="translate(-1254.29 -652.44)"/>\
			<path class="cls-2" d="M1263.91,666.68h-6.3a2.32,2.32,0,0,1-2.32-2.32l0-8.61a2.31,2.31,0,0,1,2.32-2.31h6.29a2.32,2.32,0,0,0-2.32,2.31v8.61A2.31,2.31,0,0,0,1263.91,666.68Z" transform="translate(-1254.29 -652.44)"/>\
			<line class="cls-3" x1="4.71" y1="4.4" x2="4.71" y2="9.59"/>\
		</svg>\
	',

    down:
      '\
		<svg viewBox="0 0 12 12">\
			<polyline style="stroke-linecap:round;" points="1.5,3.3 6,8.3 10.5,3.3"/>\
		</svg>\
	',

    "down-trangle":
      '\
		<svg viewBox="0 0 12 12">\
			<polygon  points="6.5,0 3.2,4.2 0,0 "/>\
		</svg>\
	',

    search:
      '\
		<svg viewBox="0 0 20 20">\
			<circle style="stroke-miterlimit:10;" cx="8.5" cy="8.5" r="7"/>\
			<line style="stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" x1="15.8" y1="15.7" x2="18.5" y2="18.4"/>\
		</svg>\
	',

    "search-thick":
      '\
		<svg viewBox="0 0 17 17">\
			<circle style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" cx="7.5" cy="7.5" r="6.5"/>\
			<line style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" x1="14" y1="14" x2="16" y2="16"/>\
			<path style="stroke-linecap:round;stroke-linejoin:round;" d="M4.5,7c0-1.9,1.6-3.5,3.5-3.5"/>\
		</svg>\
	',

    star:
      '\
		<svg viewBox="0 0 22 22">\
			<path style="fill:currentColor; stroke:none;" d="M19,9.2c-0.1-0.3-0.4-0.5-0.7-0.6L13.8,8l-2-4c-0.2-0.3-0.5-0.5-0.8-0.5c-0.3,0-0.6,0.2-0.8,0.5l-2,4L3.7,8.6C3.4,8.6,3.1,8.9,3,9.2C2.9,9.5,3,9.8,3.3,10l3.2,3.1l-0.8,4.4c-0.1,0.3,0.1,0.6,0.3,0.8c0.3,0.2,0.7,0.3,1,0.1l4-2.1l4,2.1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c0.3-0.2,0.4-0.5,0.3-0.8l-0.8-4.4l3.2-3.1C19,9.8,19.1,9.5,19,9.2z"/>\
		</svg>\
	',

    radio:
      '\
		<svg viewBox="0 0 18 18">\
			<path style="stroke:none; fill:currentColor" d="M12.7,4.3C11,6.6,8.9,9.1,8.1,12c-0.9-1-1.9-2-2.9-2.8c-0.7-0.5-1.6,0.4-0.9,1c1.3,1,2.5,2.3,3.7,3.6c0.3,0.3,1,0.2,1.1-0.3C9.6,10.3,12,7.6,13.9,5C14.4,4.3,13.2,3.6,12.7,4.3"/>\
		</svg>\
	',

    checkbox:
      '\
		<svg viewBox="0 0 18 18">\
			<path style="stroke:none; fill:currentColor" d="M12.7,4.3C11,6.6,8.9,9.1,8.1,12c-0.9-1-1.9-2-2.9-2.8c-0.7-0.5-1.6,0.4-0.9,1c1.3,1,2.5,2.3,3.7,3.6c0.3,0.3,1,0.2,1.1-0.3C9.6,10.3,12,7.6,13.9,5C14.4,4.3,13.2,3.6,12.7,4.3"/>\
		</svg>\
	',

    up:
      '\
		<svg viewBox="0 0 10 10">\
			<polyline style="stroke-linecap:round;stroke-miterlimit:10;" points="9.5,7.3 5,2.3 0.5,7.3"/>\
		</svg>\
	',

    close:
      '\
		<svg viewBox="0 0 10 10">\
			<rect x="4.5" y="-1.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0563 4.9852)" style="fill:currentColor;stroke:none;" width="1" height="13"/>\
			<rect x="-1.5" y="4.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0919 5)" style="fill:currentColor;stroke:none;" width="13" height="1"/>\
		</svg>\
	',

    "close-wide":
      '\
		<svg viewBox="0 0 12 12">\
			<line style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" x1="1" y1="1" x2="11" y2="11"/>\
			<line style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" x1="11" y1="1" x2="1" y2="11"/>\
		</svg>\
	',
  });
}

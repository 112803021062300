// 顶部用户中心入口
import "../service/user";
import registerIcon from "./icon";
import registerDrop from "./dropdown";

function extendIcon() {
  $.getComponent("icon").extendSymbols({
    "menu-account":
      '\
        <svg viewBox="0 0 17.1 18">\
          <circle style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" cx="8.7" cy="6" r="5"/>\
          <path style="stroke-width:2;stroke-linecap:round;" d="M1,17c0,0,0.8-5.5,5.4-6.4"/>\
          <path style="stroke-width:2;stroke-linecap:round;" d="M13.4,12.1c0,0,2.3,1.1,2.7,4.9"/>\
          <path style="stroke-linecap:round;stroke-linejoin:round;" d="M7.7,3.6c0,0-1.7,0.6-1.7,2.9"/>\
        </svg>\
        ',

    "menu-token":
      '\
        <svg viewBox="0 0 27.2 28.24">\
            <defs>\
                <linearGradient id="transition-1" x1="1855.76" y1="420.04" x2="1863.47" y2="420.04" gradientUnits="userSpaceOnUse">\
                    <stop offset="0" stop-color="#ffb6de" />\
                    <stop offset="1" stop-color="#feb6de" />\
                </linearGradient>\
                <linearGradient id="transition-2" x1="0" y1="14.25" x2="23.4" y2="14.25" />\
            </defs>\
            <path style="fill: #e5f1fa;" d="M1855,413.79c-.21.19-.42.39-.64.56l-.07-.07C1854.56,414.12,1854.8,414,1855,413.79Z" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: #ff6687;" d="M1861,399.29c-.24-.84-.26-3.73,1.28-3.74a1.23,1.23,0,0,1,1.13,1.25v.42h.42a1.23,1.23,0,0,1,1.25,1.13c0,1.54-2.91,1.53-3.74,1.28l-.28-.06Z" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: url(#transition-1);" d="M1863,423.3a1.67,1.67,0,0,1-1.18.49,1.69,1.69,0,0,1-1.19-.49l-3.17-3.16-1.68-1.68a13.6,13.6,0,0,0,2.58-2.16l4.64,4.64A1.67,1.67,0,0,1,1863,423.3Z" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: #ffe4f5;" class="cls-4" d="M1853.59,419.22a10.25,10.25,0,1,1-4-19.66,10.21,10.21,0,0,1,4,.83,10.25,10.25,0,0,0,0,18.82Z" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke: #f15456;" d="M1858.11,416a9.33,9.33,0,0,1-13.19,0,7.46,7.46,0,0,1,0-10.55,6,6,0,0,1,8.44,0,4.78,4.78,0,0,1,0,6.75,3.82,3.82,0,0,1-5.4,0,3.06,3.06,0,0,1,0-4.32,2.45,2.45,0,0,1,3.46,0,2,2,0,0,1,0,2.77,1.57,1.57,0,0,1-2.21,0,1.25,1.25,0,0,1,0-1.77" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: #e5f1fa;" d="M1857.77,401.74a11.52,11.52,0,0,1-.44,15.45,10.73,10.73,0,0,1-2,1.71,11.54,11.54,0,0,1-13.82-.87,11.54,11.54,0,0,0,15.14,2.19,10.73,10.73,0,0,0,2-1.71,11.53,11.53,0,0,0-.88-16.77Z" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: #ffdee3;" d="M1843.24,403.76a8.74,8.74,0,0,0-1.56,3.14,8.58,8.58,0,0,0-.16,3.17c.05.37.13.71.21,1.06a8.08,8.08,0,0,0,3.8,4.7,9.35,9.35,0,0,0,2.24.71,7.24,7.24,0,0,0,3.88-.61,9.52,9.52,0,0,0,1-.58c.21-.16.4-.34.61-.5a6.28,6.28,0,0,0,1.37-2,7,7,0,0,0,.45-1.61,5.25,5.25,0,0,0-1.85-4.7,4.22,4.22,0,0,0-4.89-.4,4.35,4.35,0,0,0-.9.79,4.67,4.67,0,0,0-.4.61,3.74,3.74,0,0,0-.32,1.11,2.84,2.84,0,0,0,.26,1.48,2.37,2.37,0,0,0,1.24,1.14,1.6,1.6,0,0,0,1.66-.34.9.9,0,0,0,.24-.5,1.51,1.51,0,0,0-.05-.37c0-.08-.08-.18-.16-.21-.34-.13-.84-.11-.84-.63a1.07,1.07,0,0,1,.82-1l.4-.08a2.68,2.68,0,0,1,.87.18l.42.26a2.15,2.15,0,0,1,.61.77c.08.18.13.34.21.53a3,3,0,0,1-.61,2.67,4.5,4.5,0,0,1-1.08.92,4.11,4.11,0,0,1-4.22-.16,6.3,6.3,0,0,1-1.27-1.11,3.78,3.78,0,0,1-.34-.55,5.66,5.66,0,0,1,2.3-7.84,8.06,8.06,0,0,1,1.66-.66,6.84,6.84,0,0,1,2.64-.11,7,7,0,0,1,2.85,1.11,6.91,6.91,0,0,1,1.48,1.27,7.49,7.49,0,0,1,1.43,2.37,8.27,8.27,0,0,1-1.56,8.32c-.32.32-.58.63-.92.92a9.24,9.24,0,0,1-7.92,2,10.1,10.1,0,0,1-4.3-2.08l-.27-.21a10.08,10.08,0,1,0,14.07-14.42,9.36,9.36,0,0,0-13.06,1.43Zm0,0" transform="translate(-1837.84 -395.55)" />\
            <path style="fill: #ff6687;" d="M1842.5,417a10.11,10.11,0,0,0,4.3,2.08,9.26,9.26,0,0,0,7.92-2c.34-.29.61-.61.92-.92a8.07,8.07,0,0,0,1.08-1.56,8.28,8.28,0,0,0,.47-6.76,7.45,7.45,0,0,0-1.43-2.37,7.45,7.45,0,0,0-4.33-2.38,6.84,6.84,0,0,0-2.64.11,8.06,8.06,0,0,0-1.66.66,5.66,5.66,0,0,0-2.3,7.84,3.78,3.78,0,0,0,.34.55,6.31,6.31,0,0,0,1.27,1.11,4.12,4.12,0,0,0,4.22.16,4.51,4.51,0,0,0,1.08-.92,3,3,0,0,0,.61-2.67c-.08-.18-.13-.34-.21-.53a2.22,2.22,0,0,0-.61-.77l-.42-.26a2.51,2.51,0,0,0-.87-.18l-.4.08a1.08,1.08,0,0,0-.82,1c0,.53.5.5.84.63a.34.34,0,0,1,.16.21,1.51,1.51,0,0,1,.05.37.9.9,0,0,1-.24.5,1.59,1.59,0,0,1-1.66.34,2.36,2.36,0,0,1-1.24-1.14,2.87,2.87,0,0,1-.26-1.48,3.85,3.85,0,0,1,.32-1.11,4.69,4.69,0,0,1,.4-.61,4.27,4.27,0,0,1,.9-.79,4.22,4.22,0,0,1,4.89.4,5.26,5.26,0,0,1,1.85,4.7,7,7,0,0,1-.45,1.61,6.27,6.27,0,0,1-1.37,2c-.21.16-.4.34-.61.5a8.91,8.91,0,0,1-1,.58,7.24,7.24,0,0,1-3.88.61,9.3,9.3,0,0,1-2.24-.71,7.78,7.78,0,0,1-1.48-1.06,7.31,7.31,0,0,1-2.32-3.64c-.08-.34-.16-.69-.21-1.06a8.51,8.51,0,0,1,.16-3.17,8.74,8.74,0,0,1,1.56-3.14,9.38,9.38,0,0,1,13.07-1.43,10.08,10.08,0,0,0-14.07,14.41,2.09,2.09,0,0,0,.26.22Zm0,0" transform="translate(-1837.84 -395.55)" />\
            <circle style="stroke-width: 2px;stroke: url(#transition-2);" cx="11.7" cy="14.25" r="10.7" />\
        </svg>\
    ',

    "menu-info":
      '\
        <svg viewBox="0 0 20 16">\
            <path d="M1918,419.11h-8.38a2,2,0,0,1-2-2v-11a2,2,0,0,1,2-2H1918a2,2,0,0,0-2,2v11A2,2,0,0,0,1918,419.11Z" transform="translate(-1907 -404)" style="fill:#currentColor;opacity:0.1" />\
            <rect x="1" y="1" width="18" height="14" rx="2" ry="2" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <circle cx="6" cy="6" r="1.5" style="fill:none;stroke:#currentColor;stroke-miterlimit:10" />\
            <line x1="10.5" y1="4.5" x2="15.5" y2="4.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="10.5" y1="7.5" x2="15.5" y2="7.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="4.5" y1="10.5" x2="15.5" y2="10.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
        </svg>\
    ',

    "menu-ticket":
      '\
        <svg viewBox="0 0 16 19">\
            <path d="M1925,380h0a2,2,0,0,1,2,2v12a2,2,0,0,1-2,2h-10a2,2,0,0,1-2-2V382a2,2,0,0,1,2-2h0" transform="translate(-1912 -378)" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <path d="M1921,396h-6a2,2,0,0,1-2-2V382a2,2,0,0,1,2-2h6a2,2,0,0,0-2,2v12A2,2,0,0,0,1921,396Z" transform="translate(-1912 -378)" style="fill:#currentColor;opacity:0.1" />\
            <line x1="6" y1="1" x2="10" y2="1" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <line x1="4.5" y1="6.5" x2="5.5" y2="6.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="4.5" y1="9.5" x2="5.5" y2="9.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="4.5" y1="12.5" x2="5.5" y2="12.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="7.5" y1="6.5" x2="11.5" y2="6.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="7.5" y1="9.5" x2="11.5" y2="9.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="7.5" y1="12.5" x2="11.5" y2="12.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
        </svg>\
    ',

    "menu-invoice":
      '\
        <svg viewBox="0 0 20.3 13.98">\
            <path d="M2066.62,413a3.33,3.33,0,0,0,3.33,3.33V419h-18.3v-2.67a3.33,3.33,0,0,0,.42-6.58.5.5,0,0,1-.42-.48V407h18.3v2.66A3.33,3.33,0,0,0,2066.62,413Z" transform="translate(-2050.64 -406.01)" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <path d="M2060.75,413a3.33,3.33,0,0,1-3.1,3.32V419h-6v-2.67a3.33,3.33,0,0,0,.42-6.58.5.5,0,0,1-.42-.48V407h6v2.25a.5.5,0,0,0,.42.48A3.33,3.33,0,0,1,2060.75,413Z" transform="translate(-2050.64 -406.01)" style="fill:#currentColor;opacity:0.1" />\
            <polyline points="8.38 4.15 10.04 6.5 11.92 4.15" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="10.04" y1="6.5" x2="10.04" y2="9.83" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="8.38" y1="6.5" x2="11.71" y2="6.5" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="8.38" y1="8.17" x2="11.71" y2="8.17" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
        </svg>\
    ',

    "menu-password":
      '\
        <svg viewBox="0 0 20.12 19.85">\
            <circle cx="8" cy="8" r="7" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <path d="M1963.5,392.27a7,7,0,1,1,0-12.62,7,7,0,0,0,0,12.62Z" transform="translate(-1952.47 -377.96)" style="fill:#currentColor;opacity:0.1" />\
            <path d="M1956.68,385.19a3.22,3.22,0,0,1,2.59-2.68" transform="translate(-1952.47 -377.96)" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
            <line x1="18.49" y1="18.85" x2="12.87" y2="13.22" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <line x1="17.13" y1="17.15" x2="19.12" y2="15.15" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <line x1="15.1" y1="15.12" x2="16.34" y2="13.87" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
        </svg>\
    ',

    "menu-settings":
      '\
        <svg viewBox="0 0 21.36 21">\
            <path d="M1931.83,450a2.37,2.37,0,0,1-1.7-.64,2.39,2.39,0,0,0-3.25,0,2.35,2.35,0,0,1-1.6.64,2.39,2.39,0,0,1-2.4-2.56c0-.06,0-.11,0-.17a2.39,2.39,0,0,0-2-2.37,2.38,2.38,0,0,1-2-2.38,2.35,2.35,0,0,1,1-2,2.37,2.37,0,0,0,1-2,2.44,2.44,0,0,0-.31-1.19,2.34,2.34,0,0,1-.32-1.2,2.39,2.39,0,0,1,3.09-2.28,2.29,2.29,0,0,0,.7.1,2.39,2.39,0,0,0,2.22-1.51,2.39,2.39,0,0,1,4.45,0,2.4,2.4,0,0,0,1.1,1.23,2.37,2.37,0,0,1-1.82.18,2.39,2.39,0,0,0-3.09,2.28,2.34,2.34,0,0,0,.32,1.2,2.44,2.44,0,0,1,.31,1.19,2.37,2.37,0,0,1-1,2,2.35,2.35,0,0,0-1,2,2.38,2.38,0,0,0,2,2.38,2.39,2.39,0,0,1,2,2.37c0,.06,0,.11,0,.17A2.39,2.39,0,0,0,1931.83,450Z" transform="translate(-1917.82 -430.03)" style="fill:#currentColor;opacity:0.1" />\
            <path d="M1930.72,432.54h0a2.39,2.39,0,0,0,2.92,1.41h0a2.39,2.39,0,0,1,2.77,3.48h0a2.39,2.39,0,0,0,.72,3.16h0a2.39,2.39,0,0,1-1,4.33h0a2.39,2.39,0,0,0-2,2.54h0a2.39,2.39,0,0,1-4,1.93h0a2.39,2.39,0,0,0-3.24,0h0a2.39,2.39,0,0,1-4-1.93h0a2.39,2.39,0,0,0-2-2.54h0a2.39,2.39,0,0,1-1-4.33h0a2.39,2.39,0,0,0,.72-3.16h0a2.39,2.39,0,0,1,2.77-3.48h0a2.39,2.39,0,0,0,2.92-1.41h0A2.39,2.39,0,0,1,1930.72,432.54Z" transform="translate(-1917.82 -430.03)" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px" />\
            <circle cx="10.68" cy="10.5" r="2.28" style="fill:none;stroke:#currentColor;stroke-linecap:round;stroke-linejoin:round" />\
        </svg>\
    ',

    user: '\
    <svg viewBox="0 0 17.1 18">\
      <circle style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" cx="8.7" cy="6" r="5"/>\
      <path style="stroke-width:2;stroke-linecap:round;" d="M1,17c0,0,0.8-5.5,5.4-6.4"/>\
      <path style="stroke-width:2;stroke-linecap:round;" d="M13.4,12.1c0,0,2.3,1.1,2.7,4.9"/>\
      <path style="stroke-linecap:round;stroke-linejoin:round;" d="M7.7,3.6c0,0-1.7,0.6-1.7,2.9"/>\
    </svg>\
    ',

    exit: '\
    <svg viewBox="0 0 16 17">\
      <path style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" d="M11,16H3c-1.1,0-2-0.9-2-1.9V2.9C1,1.9,1.9,1,3,1h8"/>\
      <line style="stroke-linecap:round;stroke-linejoin:round;" x1="5.5" y1="8.5" x2="15.5" y2="8.5"/>\
      <line style="stroke-linecap:round;stroke-linejoin:round;" x1="12.5" y1="5.5" x2="15.5" y2="8.5"/>\
      <line style="stroke-linecap:round;stroke-linejoin:round;" x1="12.5" y1="11.5" x2="15.5" y2="8.5"/>\
    </svg>\
  ',

    videos:
      '\
    <svg viewBox="0 0 18 14">\
      <path style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" d="M15,4.7l2-0.8v7.2L12,9v2c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V3c0-1.1,0.9-2,2-2h7c1.1,0,2,0.9,2,2v2"/>\
      <line style="stroke-linecap:round;stroke-linejoin:round;" x1="4.5" y1="8.5" x2="4.5" y2="4.5"/>\
    </svg>\
  ',

    photos:
      '\
    <svg viewBox="0 0 18 15">\
      <circle style="fill:currentColor;stroke:none;" cx="5.5" cy="5.5" r="1.5"/>\
      <path style="stroke-width:2;stroke-linecap:round;stroke-linejoin:round;" d="M17,8.1V12c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V3c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2"/>\
      <path style="stroke-linecap:round;stroke-linejoin:round;" d="M10.4,13.9H7.1L1.5,14c0-0.2,1.3-2.2,2.5-3.7c0.8-1,2.3-1.1,3.1-0.1l1.2,1.4L10.4,13.9z"/>\
      <path style="stroke-linecap:round;stroke-linejoin:round;" d="M16,13.9L7.1,14c0,0,0,0,0-0.1h3.3l-2.1-2.4c0.6-1.2,1.4-2.6,2.1-3.8c0.6-1.1,2.1-1,2.7,0.1L16,13.9z"/>\
    </svg>\
  ',
    file: '\
    <svg viewBox="0 0 24.4 22.7">\
      <style type="text/css">\
        .st0{stroke-width:1.2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\
      </style>\
      <path id="XMLID_28_" class="st0" d="M1.8,6.4v-2C1.8,3.6,2.3,3,3,3l0,0h9.2c0,0,1.2-2.4,2.4-2.4h6.9c0.6,0,1,0.6,1,1.4v6.2"/>\
      <path id="XMLID_30_" class="st0" d="M22.6,8.6H12.1c0,0-1.2-2.2-2.4-2.2h-8c-0.6,0-1.1,0.5-1.1,1.1L1.7,21c0,0.6,0.5,1.1,1.2,1.1h18.6c0.7,0,1.2-0.5,1.2-1.1l1.1-11.2C23.8,9.2,23.2,8.6,22.6,8.6z"/>\
    </svg>\
    ',
    freeorder:
      '\
    <svg viewBox="0 0 31 27">\
        <style type="text/css">\
            .st0{clip-path:url(#SVGID_2_);fill:#ACD362;}\
            .st1{clip-path:url(#SVGID_2_);fill:#FFFFFF;}\
            .st2{clip-path:url(#SVGID_2_);fill:none;stroke:#CBEA86;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}\
        </style>\
        <g>\
            <defs>\
                <rect id="SVGID_1_" x="0" y="0" width="31" height="26.7"/>\
            </defs>\
            <clipPath id="SVGID_2_">\
                <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>\
            </clipPath>\
            <path class="st0" d="M29.5,13.1L12.6,4.6c-0.5-0.2-1-0.3-1.5-0.3c-0.3,0-0.5,0-0.8,0.1L3.8,6.7c-0.5,0.2-1,0.6-1.3,1.1,C2.2,8.2,2.1,8.8,2.2,9.4c0,0.1,0.1,0.2,0.1,0.3l2.2,6.7c0.1,0.3,0.3,0.7,0.6,0.9c0.2,0.3,0.5,0.5,0.9,0.7l16.9,8.5,c1.3,0.7,2.9,0.1,3.6-1.2l4.3-8.6c0.3-0.5,0.3-1.1,0.2-1.7C30.8,14.2,30.3,13.5,29.5,13.1"/>\
            <path class="st1" d="M9.3,10.2c0.1,0.4,0,0.9-0.2,1.3c-0.5,1-1.7,1.4-2.7,0.9c-0.6-0.3-1-0.8-1.1-1.5c-0.1-0.4,0-0.9,0.2-1.3,c0.2-0.5,0.7-0.8,1.2-1c0.5-0.2,1.1-0.1,1.5,0.1c0.5,0.3,0.9,0.7,1,1.3C9.2,10.1,9.3,10.2,9.3,10.2"/>\
            <path class="st2" d="M5.5,9.7c0,0-3.5-1.6-4.2-3.5C0.3,3.8,2.1-0.4,7.7,1.4c1.9,0.6,7.9,4.7,9.5,2.1"/>\
            <path class="st1" d="M12,9.5c0.1-0.3,0.5-0.4,0.7-0.2l3.2,1.6c0.2,0.1,0.3,0.4,0.2,0.7c-0.1,0.2-0.4,0.3-0.7,0.2l-2.7-1.3L12,11.8,l2.4,1.2c0.2,0.1,0.3,0.4,0.2,0.7c-0.1,0.2-0.4,0.3-0.7,0.2l-2.4-1.2l-0.9,1.8c-0.1,0.3-0.4,0.4-0.7,0.2c-0.3-0.1-0.4-0.5-0.3-0.7,L12,9.5z"/>\
            <path class="st1" d="M16.3,13.3c0.1-0.3,0.4-0.4,0.7-0.2c0.3,0.1,0.4,0.4,0.2,0.7l-0.2,0.4c0.5-0.4,1.1-0.6,1.5-0.4,c0.3,0.1,0.4,0.4,0.2,0.7c-0.1,0.3-0.4,0.3-0.6,0.3c-0.7-0.2-1.4,0.1-1.9,1.1L15.7,17c-0.1,0.3-0.4,0.4-0.7,0.2,c-0.3-0.1-0.4-0.4-0.2-0.7L16.3,13.3z"/>\
            <path class="st1" d="M19.5,19.5c-1.2-0.6-1.7-1.9-1.1-3.2l0,0c0.6-1.2,1.9-1.7,3.1-1.2c1.3,0.6,1.4,2,1,3c-0.1,0.3-0.4,0.4-0.7,0.2,l-2.6-1.3c-0.2,0.7,0.1,1.3,0.7,1.6c0.4,0.2,0.8,0.2,1.2,0.1c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.4,0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2,C20.7,19.9,20.1,19.8,19.5,19.5 M21.6,17.5c0.2-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.2,0-1.6,0.6L21.6,17.5z"/>\
            <path class="st1" d="M24.2,21.8c-1.2-0.6-1.7-1.9-1.1-3.2l0,0c0.6-1.2,1.9-1.7,3.1-1.2c1.3,0.6,1.4,2,1,3c-0.1,0.3-0.4,0.4-0.7,0.2,l-2.6-1.3c-0.2,0.7,0.1,1.3,0.7,1.6c0.4,0.2,0.8,0.2,1.2,0.1c0.1,0,0.2,0,0.3,0c0.2,0.1,0.3,0.4,0.2,0.6c-0.1,0.1-0.2,0.2-0.3,0.2,C25.4,22.2,24.8,22.2,24.2,21.8 M26.3,19.9c0.2-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.2,0-1.6,0.6L26.3,19.9z"/>\
            <path class="st1" d="M25,24.3c0-0.3-0.3-0.5-0.6-0.3l-0.2,0.4c0,0.1-0.2,0.1-0.2,0.1l-3.4-1.7c-0.1,0-0.1,0-0.2,0,c-0.1,0-0.2,0.2-0.2,0.3c0,0.1,0.1,0.2,0.1,0.2l3.4,1.7c0.4,0.2,0.9,0.3,1-0.1C24.8,24.8,25,24.5,25,24.3"/>\
        </g>\
    </svg>\
    ',
  });
}
export default function registerAccountEntry($, apower) {
  registerIcon($, apower);
  registerDrop($, apower);
  extendIcon();
  // 尝试删除多余元素 <div f-hide="user.isLogined()"><icon type="user"></icon><span class="login">{Login@@001166}</span></div>
  $.registerComponent("header-account-entry", "drop", {
    template: `
          <div class="drop header-account-entry" @click="onClick">
            <div class="header-account-entry-img-wrap" f-show="user.isLogined() || showportrait">
              <img f-if="user.getAvatar()" :src="user.getAvatar()" width="32" height="32" alt="avatar" />
              <span f-if="!user.getAvatar()" class='default-avatar'></span>
            </div>
            <span class="header-account-wrap-name">{{user.getId() ? user.getName() : ''}}</span>
            <div class="drop-menu header-account-menu" :class="menuClass" ref="menu">
              <div class="ap-trangle"></div>
              <div class="header-account-nav">
                <div class="header-account-nav-top" f-show="user.isLogined()">
                  <div class="header-account-nav-avatar" f-if="user.getAvatar()"><img f-if="user.getAvatar()" :src="user.getAvatar()" width="32" height="32" alt="avatar" /></div>
                  <span f-if="!user.getAvatar()" class='default-avatar'></span>
                  <div class="header-account-nav-name">{{user.getName()}}</div>
                </div>
                <a class="header-account-nav-button" f-show="user.isLogined() && !user.inMyAccountDomain" target="_blank" :href="getAccountURL()">
                  <icon type="user"></icon>
                  {My account@@001167}
                </a>
                <a class="header-account-nav-button" f-for="button of customButtons" :href="button.url" f-hide="!user.isLogined()">
                  <icon :type="button.icon"></icon>
                  {{button.text}}
                </a>
                <div class="header-account-nav-button header-account-nav-exit" f-show="user.isLogined()" @click="user.logout">
                  <icon type="exit"></icon>
                  {Exit@@001168}
                </div>
              </div>
            </div>
          </div>
        `,

    url: "/",

    customButtons: null,

    defaultPanelType: "",

    onCreated() {
      this.license = apower.license;
      this.user = apower.user;
      apower.user.syncLoginState = this.onLogined.bind(this);
      apower.user.syncLogoutState = this.onLogout.bind(this);

      // 如果是在微信内打开则自动登陆
      let query = $.parseQuery(location.search);
      let weixinAutoLogin = query.weixin_auto_login;
      let code = query.code;
      let UA = window.navigator.userAgent.toLowerCase();
      let isMicroMessenger = UA && UA.indexOf("micromessenger") > 0;
      let isLogined = apower.user.isLogined();
      // 第一次进入页面，没有code参数
      if (isMicroMessenger && !code && !isLogined) {
        // 带有特定参数且浏览器为微信浏览器,跳转
        let appid = "wx1d396e446d0ecf21";
        let redirect_uri = encodeURIComponent(
          `https://${apower.user.origin.replace(
            /^https:\/\//,
            ""
          )}/oauth/redirect?callback=${encodeURIComponent(
            window.location.href
          )}`
        );
        let state = "login";
        window.location = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
      }
      // 跳转回来，带有code参数
      if (code && weixinAutoLogin) {
        $.ajax({
          url: `https://wechat.aoscdn.com/api/user/code/${code}`,
          type: "GET",
          success: async function (res) {
            if (res && res.data) {
              // 保存微信用户信息到cookie供其他页面使用
              let storage = window.localStorage;
              if (storage) {
                storage.setItem(
                  "weixin_user_info",
                  JSON.stringify(res.data.user)
                );
              } else {
                setCookie("weixin_user_info", JSON.stringify(res.data.user), 3);
              }
              await apower.user.loginByPost({
                provider: "weixin",
                unique_id: res.data.user.unionid,
                open_id: res.data.user.openid,
                language: apower.lang,
                registed_app: "apowersoft",
                type: 5,
              });
            }
          },
          error: function (err) {
            console.log(err);
          },
        });
      }
    },

    onClick() {
      if (!apower.user.isLogined()) {
        import("./login").then((module) => {
          module.default($, apower);
          $.newComponent("login", {
            defaultPanelType: this.defaultPanelType,
          }).show();
        });
      }
    },

    getAccountURL() {
      // 手机端跳转到 http://myaccountm.apowersoft.com 对应的语言
      // if ($.env.isMobile) {
      //   return $.useQuery(apower.getMyAccountMobileDomain() + "/#/", {
      //     identity_token: apower.user.getToken(),
      //     lang: apower.lang,
      //   });
      // } else {
      let url = apower.getMyAccountDomain() + apower.getRegionPath() + "/";
      if (apower.getMyAccountDomain().match(/.cn$/)) {
        url = apower.getMyAccountDomain() + "/";
      }
      return $.useQuery(url, {
        identity_token: apower.user.getToken(),
      });
      // }
    },

    //这里可以用于同步账户 session
    onLogined() {},

    //这里可以用于退出当前系统的账户
    onLogout() {},

    // 未登录的时候不显示下拉menu
    willShowMenu() {
      return apower.user.isLogined();
    },
  });
}

import "lazysizes";
import $ from "@apowersoft/jqueryvm";
import apower from "./service/apower";
import "./styles/base.css";
import "./styles/product.css";
import registerHeader from "./components/header";
import registerAccountEntry from "./components/headerAccountEntry";
import registerRating from "./components/rating";
import "./styles/components/footer.css";
import LazyLoad from "./service/lazyLoad";
import { callOnInteractive } from "./service/utils";

$.callOnInteractive = callOnInteractive;
const primaryTask = [registerRating, registerHeader, registerAccountEntry];
const secondaryTask = [];

function applyComponents(components, scope = "body") {
  components.forEach((component) => setTimeout(() => component($, apower)));
  setTimeout(function () {
    $.newComponent({
      el: scope,
    });
  });
}

function invokeSecondaryTask() {
  import("./service/commonComponents").then((module) => {
    secondaryTask.unshift(...module.default);
    applyComponents(secondaryTask);
  });
}

export default function (fn) {
  fn(function (options) {
    const created = options.created || Function.prototype;
    secondaryTask.push(($, apower) => created($, apower, LazyLoad));
  });
  if ($.env.isMobile) {
    window.asyncMode = true; // jqueryVM 改为异步编译模式
    applyComponents(primaryTask);
    callOnInteractive(() => {
      window.asyncMode = false;
      invokeSecondaryTask();
    });
  } else {
    applyComponents([...primaryTask]);
    invokeSecondaryTask();
  }
}

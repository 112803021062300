/* eslint-disable */
import $ from "@apowersoft/jqueryvm";
import apower from "./apower"
let { tr } = apower;

//管理登录账户信息
//事件:
//	logined: 当登入时触发, 注意从本地存储恢复账户信息不会触发此事件
//	logout: 当登出时触发
//	binded: 当绑定邮箱或者手机号时触发
//	modify(data): 当账户信息改变时触发
//	logincompleted: 完成登录并且处理完联系信息绑定, 此时可以重定向页面
apower.user = {
  __proto__: $.Emitter.prototype,

  //网络模块
  // origin: "https://gw.aoscdn.com/base/passport/v1/api",
  origin: apower.endpoint.getPassportEndpoint(),
  root: 'https://gw.aoscdn.com',
  devRoot:'https://devgw.aoscdn.com',
  isDev(){
    return location.hostname.indexOf('dev-')>-1||
    location.hostname.indexOf('.test')==location.hostname.length-5
  },
  switchOrigin(url){
    // 切换 dev和生产
    let { user } = apower;
    if(user.isDev()){
      return url.replace(user.root,user.devRoot)
    }
    return url;
  },
  req: $.req.overwrite(
    (url, options) => {
      let { lang, user } = apower;

      let query = $.parseQuery(url);
      let queryString = url.substring(
        0,
        url.indexOf("?") === -1 ? url.length : url.indexOf("?")
      );

      url = user.origin + queryString;

      options.data = Object.assign({}, query, options.data);

      if (typeof options.data === "object") {
        options.data.brand_id = $.store.get("brand_id");
        // acethinker的brand_id为1
        if (apower.app && apower.app.isAceThinker) {
          options.data.brand_id = "1";
        }
        options.data.app_id = $.store.get("app_id");
        options.data.language = lang;
      }

      let apiToken = user.apiToken || (user.info && user.info.api_token);

      if (apiToken && !url.endsWith("api/login")) {
        options.headers = {
          Authorization: "Bearer " + apiToken,
        };
      }
      return {
        url,
        options,
      };
    },

    ({ ok, data }) => {
      let status = data && data.status ? data.status : null;
      data = data && data.data ? data.data : data;
      return {
        ok,
        data,
        status,
      };
    }
  ),

  //辅助环境变量
  //是否在 myaccount 域名下
  inMyAccountDomain:
    location.host.startsWith("myaccount") ||
    location.host.startsWith("dev-myaccount-apowersoft-com"),

  //是否由软件内嵌
  inSoftware: false,

  brand: null,

  //用户数据模块
  info: null,

  token: null,

  license: null,

  bindings: null,

  //用于网站处理同步登录信息到当前域名
  syncLoginState: null,

  syncLogoutState: null,

  appMapping: {
    "www.videograbber.net": "videograbber web all",
    "myaccount.apowersoft.cn": "myaccount-apowersoft web china",
    "myaccount.apowersoft.com": "myaccount-apowersoft web overseas",
    "myaccountm.apowersoft.com": "myaccount-apowersoft wap overseas",
    "myaccountm.apowersoft.cn": "myaccount-apowersoft wap china",
    "www.apowersoft.com": "apowersoft web overseas",
    "www.apowersoft.cn": "apowersoft web china",
  },

  getAppName() {
    return this.appMapping[window.location.host] || "apowersoft web overseas";
  },

  triggerEvent(e) {
    window.dispatchEvent(e);
  },

  async init() {
    $.Emitter.call(this);

    this.info = $.store.get("account_user");
    this.apiToken = $.store.get("account_api_token");
    this.identityToken = $.store.get("account_id_token");

    // 用户中心请求需要带上app_id和brand_id
    let appName = this.getAppName();
    let { ok, status, data } = await apower.user.req.get(
      "/apps/name?name=" + appName
    );
    if (ok && status === 200) {
      let { brand_id, app_id } = data;
      $.store.set("brand_id", brand_id);
      $.store.set("app_id", app_id);
    }

    if (this.identityToken) {
      this.req
        .post("/login", {
          identity_token: this.identityToken,
          type: 15,
        })
        .then(({ ok, data, status }) => {
          if (ok && status === 200) {
            if (data && data.user && (data.user.user_id || data.user.uid)) {
              this.set({
                user: data.user,
              });
            } else if (status == -214) {
              this.popupLoginWin();
            }
          }
        });
    }

    //第三方登录的跳转
    //这里要先等待 route 初始化
    $.onReady(() => {
      $.defer(() => {
        // 参数中带有第三方身份信息，触发第三方登录
        if (this.willHandleThirdParty()) {
          this.handleThirdPartyCallback();
        }
        // 参数中带有身份信息，触发自动登录
        if (this.willHandleAutoLogin()) {
          this.handleAutoLogin();
        }
      });
    });
  },

  //获得用户 id
  getId() {
    return this.info ? this.info.user_id || this.info.uid : "";
  },

  //获得用户名称
  getName() {
    if (this.info) {
      return this.info.nickname || this.info.email.replace(/@.+/, "");
    } else {
      return tr("Anonymous@@001248");
    }
  },

  //获得头像
  getAvatar() {
    return this.info
      ? this.info.avatar
        ? this.info.avatar
        : this.info.avatar_url
      : "";
  },

  //是否登陆
  isLogined() {
    // community的处理方式不一样
    if (window.location.pathname.indexOf("/community") > -1) {
      return document
        .getElementsByClassName("loggin-user-name")[0]
        .classList.contains("haslogin");
    } else {
      return this.getId() > 0;
    }
  },

  //是否是首次登陆, 首次登陆的用户会收到一些额外的提醒
  isFirstTimeLogin() {
    return this.info && this.info.last_login_time == "0";
  },

  //是否已绑定邮箱或者手机号
  isBinded() {
    return this.info.telephone || this.info.email;
  },

  //是否为匿名账户, 此时 email 的 @ 之前的字段为订单号
  isAnonymous() {
    return (
      this.info && this.info.email && this.info.email.endsWith("@anonymous.com")
    );
  },

  //在 URL 中添加登录信息以登录其他域名
  addTokenToURL(url) {
    if (!url) {
      return "";
    }

    let identity_token = this.getToken();

    if (identity_token) {
      return $.useQuery(url, {
        identity_token,
      });
    } else {
      return url;
    }
  },

  //获得用于登录其他平台的 token 信息
  getToken() {
    return this.identityToken || (this.info ? this.info.identity_token : null);
  },

  //更新并且保存账户基本信息
  async modify(info) {
    let userId = this.getId();
    let oldInfo = $.extend({}, this.info);

    Object.assign(this.info, info);
    $.digest();
    let { ok, data, status } = await this.req.put(`/users/${userId}`, info);

    if (ok) {
      if (data) {
        this.info = Object.assign({}, data, data.user_profile);
      }

      $.store.set("account_user", this.info);
      if (window.Cookies && this.info.identity_token) {
        Cookies.set("X_USER_IDENTITY_TOKEN", this.info.identity_token);
      }
      this.trigger("modify", this.info);
    } else {
      this.info = oldInfo;
    }

    return {
      ok,
      status,
    };
  },

  //更新用户数据
  async set({ user, api_token, identity_token }) {
    let isLoginedBefore = this.isLogined();
    if (user) {
      this.info = Object.assign({}, user, user.user_profile);
    }

    if (api_token) {
      this.apiToken = api_token;
    }

    if (identity_token) {
      this.identityToken = identity_token;
      this.bindings = null; //登录之后必须清除之前遗留的 bindings
      if (this.info) {
        this.info.identity_token = identity_token;
      }
    }

    $.store.set("account_user", this.info);
    if (window.Cookies && this.info.identity_token) {
      Cookies.set("X_USER_IDENTITY_TOKEN", this.info.identity_token);
    }
    $.store.set("account_api_token", this.apiToken);
    $.store.set("account_id_token", this.identityToken);

    if (
      (this.isLogined() && !isLoginedBefore) ||
      identity_token ||
      (window.location.pathname.indexOf("/community") > -1 && !isLoginedBefore)
    ) {
      if (this.syncLoginState) {
        await this.syncLoginState();
      }

      this.trigger("logined");

      try {
        var event = document.createEvent("Event");
        event.initEvent("wx_user_login", true, true);
        this.triggerEvent(event);
      } catch (err) {
        console.log(err);
      }

      if (this.isBinded()) {
        this.trigger("logincompleted");
      }
    }
  },

  //更新头像
  async changeAvatar(file) {
    // todo
    let url = await apower.uploadAvatar(file);
    if (url) {
      let { ok } = await apower.user.modify({
        avatar: url,
      });
      if (ok) {
        return {
          ok: true,
        };
      } else {
        $.notify.alert(tr("Failed to change avatar!@@001249"));
        return {
          ok: false,
        };
      }
    } else {
      $.notify.alert(tr("Failed to upload avatar!@@001250"));
      return {
        ok: false,
      };
    }
  },

  //登录
  async loginByPost(post) {
    if (post.identity_token) {
      post.identity_token = post.identity_token.replace(/%2c/gi, ",");
      post.type = 15;
    }
    if (!$.store.get("app_id") && !$.store.get("brand_id")) {
      await this.init();
    }
    // acethinker 登陆带上产品名
    if (apower.app && apower.app.env === "software") {
      InvokeApp("get-passport-info", {}, async function (res) {
        post["registed_app"] = res.data.app_info.name;
        let { ok, data, status } = await this.req.post("/login", post);

        if (ok && status === 200) {
          this.set(data);
        }

        return {
          ok,
          status,
        };
      });
    }
    let { ok, data, status } = await this.req.post("/login", post);

    if (ok && status === 200) {
      this.set(data);
    }

    return {
      ok,
      status,
    };
  },

  //是否要处理第三方回调
  willHandleThirdParty() {
    let query = $.parseQuery(location.search);
    return (query.unique_id && query.provider) || query.state;
  },

  //处理绑定回调, 可能是登录也可能是绑定第三方
  async handleThirdPartyCallback() {
    let { route } = apower;
    let query = $.parseQuery(location.search);

    if (route) {
      route.loading = true;
    }

    if (query.action === "binding") {
      await this.bindWithThirdParty();
    } else if (query.action === "login") {
      await this.loginWithThirdParty();
    }

    if (route) {
      route.loading = false;
    }
  },

  //执行第三方绑定
  async bindWithThirdParty() {
    let { route, license } = apower;
    let query = $.parseQuery(location.search);
    let userId = this.getId();
    let post = {};

    post.unique_id = query.unique_id;
    post.provider = query.provider;
    post.registed_app = license.getRegisteredApp();

    if (this.inMyAccountDomain) {
      route.goto("/");
    }

    let { ok, data, status } = await this.req.put(
      `/users/${userId}/oauthbindings`,
      post
    );
    if (ok && status === 200) {
      $.notify.success(
        tr(
          "{0} account binded!@@001251",
          this.getBindingProviderName(query.provider)
        )
      );
      this.readBindings();
    } else {
      if (status == -206) {
        $.notify.alert(
          tr(
            "The {0} account has binded to another account!@@001383",
            this.getBindingProviderName(query.provider)
          )
        );
      } else {
        $.notify.alert(tr("Failed to bind to third party accounts!@@001252"));
      }
    }
  },

  //第三方登录
  async loginWithThirdParty() {
    let query = $.parseQuery(location.search);
    let post = {
      state: query.state,
      type: 17,
    };

    let { ok, status } = await this.loginByPost(post);
    if (ok && status === 200) {
      this.onAfterThirdPartyLogin();
    } else {
      $.notify.alert(tr("Failed to login with third party accounts!@@001253"));
    }
  },

  // 第三方登录回调
  onAfterThirdPartyLogin() {
    let { route, license } = apower;
    let query = $.parseQuery(location.search);

    if (this.inMyAccountDomain) {
      if (!apower.app.isFromSoftware) {
        // 网页端发起的回调
        if (!this.isBinded()) {
          route.goto("/binding" + location.search);
        } else {
          route.goto("/" + location.search);
        }
      } else {
        // 软件端发起的回调
        if (apower.app.env === "software") {
          if (!this.isBinded()) {
            route.goto("/binding" + location.search);
          } else {
            route.goto("/congrats-logined" + location.search);
          }
        } else {
          if (route.isInSoftware) {
            apower.app.returnToSoftware("third-party-login-from-software");
          } else {
            apower.app.returnToSoftware("third-party-login-from-web");
          }
        }
      }
    } else {
      let url = location.href.replace(
        /&?(?:unique_id|provider)=(?:[^&])+/g,
        ""
      );
      history.replaceState(null, null, url);

      if (!this.isBinded()) {
        $.newComponent("binding", {
          query,
        }).show();
      }
    }
  },

  onAfterBindingContact() {
    this.trigger("logincompleted");
  },

  //是否要自动登录
  willHandleAutoLogin() {
    // 登录成功按钮不自动登录
    let { route } = apower;
    let query = $.parseQuery(location.search);
    if (!route) {
      return (
        query.identity_token ||
        query.user_token ||
        (query.uid && (query.ctoken || query.sylogin_hash))
      );
    } else {
      return (
        (query.identity_token ||
          query.user_token ||
          (query.uid && (query.ctoken || query.sylogin_hash))) &&
        route.path !== "/congrats-registered" &&
        route.path !== "/congrats-logined" &&
        route.path !== "/congrats-activated" &&
        route.path !== "/binding"
      );
    }
  },

  //从 identity_token 完成自动登录
  async handleAutoLogin() {
    let { route } = apower;
    let {
      identity_token,
      token,
      user_token,
      uid,
      ctoken,
      sylogin_hash,
    } = $.parseQuery(location.search);
    identity_token = identity_token || token || user_token;
    // todo
    let { ok, status } = await this.loginByPost({
      identity_token,
      uid,
      ctoken,
      sylogin_hash,
      type: 15,
    });
    if (
      route &&
      (route.path === "/change-password" ||
        route.path === "/background-remover-cdkey")
    ) {
    } else if (this.inMyAccountDomain) {
      // lightmv逻辑
      if (location.pathname.indexOf("/token") > -1) {
        let url = location.href.replace(
          /&?(?:identity_token|uid|ctoken|sylogin_hash)=(?:[^&])+/g,
          ""
        );
        url = url.replace(/\?$/, "");
        history.replaceState(null, null, url);
      } else {
        route.goto("/");
      }
    } else {
      let url = location.href.replace(
        /&?(?:identity_token|uid|ctoken|sylogin_hash)=(?:[^&])+/g,
        ""
      );
      url = url.replace(/\?&/, "?");
      history.replaceState(null, null, url);
    }
  },

  //读取第三方绑定数据
  async readBindings() {
    let userId = this.getId();
    let { ok, data, status } = await this.req.get(
      `/users/${userId}/oauthbindings`
    );

    if (ok) {
      this.bindings = data.list;
    }

    return {
      ok,
      status,
    };
  },

  //根据名称获取单个绑定数据
  getBinding(name) {
    if (this.bindings) {
      return this.bindings.find((item) => item.provider === name);
    } else {
      return null;
    }
  },

  //获取绑定的英文名称
  getBindingProviderName(name) {
    let nameMap = {
      qq: "QQ",
      weixin: "微信",
      weibo: "微博",
      dingtalk: "钉钉",
    };

    return nameMap[name] || name.slice(0, 1).toUpperCase() + name.slice(1);
  },

  //绑定第三方账户
  async bindThirdParty(post) {
    let userId = this.getId();
    let { ok, status } = await this.req.put(
      `/users/${userId}/oauthbindings`,
      post
    );

    return {
      ok,
      status,
    };
  },

  //绑定邮箱或者手机
  async bindContact(post) {
    let userId = this.getId();
    let { ok, data, status } = await this.req.put(
      `/users/${userId}/contactinfo`,
      post
    );

    if (ok && status === 200) {
      this.set({
        user: data,
      });
    }

    return {
      ok,
      status,
    };
  },

  //退出
  async logout() {
    let { route } = apower;
    let token = this.getToken();

    this.info = null;
    this.apiToken = "";
    this.identityToken = "";
    this.bindings = null;

    $.store.remove("account_user");
    if (window.Cookies) {
      Cookies.remove("X_USER_IDENTITY_TOKEN");
    }
    $.store.remove("account_api_token");
    $.store.remove("account_id_token");

    if (route) {
      route.clearAllCache();
    }

    if (route && this.inMyAccountDomain) {
      route.goto("/login");
    }

    if (this.syncLogoutState) {
      await this.syncLogoutState(token);
    }

    this.trigger("logout");
    try {
      var event = document.createEvent("Event");
      event.initEvent("wx_user_logout", true, true);
      this.triggerEvent(event);
    } catch (err) {
      console.log(err);
    }
  },

  //弹出 login 窗口
  popupLoginWin(fn, hideThirdPart) {
    var _this = this;
    return new Promise((resolve) => {
      if (!$("[is=login]").length) {
        //Sophia添加onShow，onHide事件，showmore视频播放页弹出登陆弹窗时停止播放视频，关闭弹窗时播放视频
        import("../components/login").then((module) => {
          module.default($, apower);
          let loginWin = $.newComponent("login", {
						hideThirdPartLogin: !!hideThirdPart,
            onShow() {
              _this.trigger("showLoginWin");
            },
            onHide() {
              _this.trigger("hideLoginWin");
            },
          });
					loginWin.show();
          // $.newComponent("login", {
          //   defaultPanelType: this.defaultPanelType,
          // }).show();
					loginWin.once('cancelLogin', () => {
						if(fn) fn = null;
					})
        });
      }

      apower.user.once("logined", () => {
        if (fn) {
          fn();
        }
        resolve();
      });
    });
  },
};

(async function () {
  await apower.user.init();
})();

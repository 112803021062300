
export default function registerDropdown($, apower) {
//drop down
  $.registerComponent('drop', {

    template: `
		<div class="drop">
			<slot></slot>
			<icon :type="icon"></icon>
			<div class="drop-menu" :class="menuClass" ref="menu">
				<div class="ap-trangle"></div>
			</div>
		</div>
	`,

    align: 'b',

    gap: '0',

    menuClass: '',

    open: false,

    event: 'hover',

    icon: 'down',

    onReady () {
      let layer = this.el.find('ul')
      if (!layer.closest(this.menu).length) {
        this.menu.append(layer)
      }

      //old API
      if (this.isOld) {
        this.el.css('overflow', 'visible')
        this.menu.css('position', 'fixed')
      }
      else {
        this.menu.appendTo(document.body)
      }

      if (this.event === 'hover') {
        if (window.innerWidth <= 768) { // 移动设备
          this.el.on('click', this.onMouseEnter, this)
        } else { // web端
          this.el.on('mouseenter', this.onMouseEnter, this)
        }

      }
      else {
        this.el.on(this.event, this.onClick, this)
      }

      //mousedown 触发时, 防止其造成编辑器失去焦点
      if (this.event === 'mousedown') {
        this.menu.on('mousedown', this.preventMouseDown, this)
      }

      this.menu.on('click', this.onClickMenu, this)
    },

    onMouseEnter (e) {
      if ($(e.target).closest(this.menu).length) {
        return
      }

      if (this.willShowMenu()) {
        this.onBeforeMenuShow()
        this.showMenu()

        if($.env.isTouch){
          $("body").children().on('mousedown', this.onDocMouseDown, this)
        }

        $.onceAllLeave([this.el, this.menu], 200, this.hideMenu, this)
      }
    },

    willShowMenu () {
      return true
    },

    onBeforeMenuShow () {},

    showMenu () {
      if (this.el[0].className.includes('active') && window.innerWidth <= 768) { // 只有在移动端才可以实现
        this.hideMenu()
      } else {
        this.menu.stop().fadeIn(200)
        this.alignMenu()
        this.el.addClass('active')
        this.open = true

        $.nextTick(this.onAfterMenuShow, this)
      }
    },

    alignMenu () {
      this.menu.alignTo(this.el, this.align, this.gap, true)
    },

    onAfterMenuShow () {},

    onClickMenu () {
      this.hideMenu()
    },

    hideMenu () {
      this.menu.stop().fadeOut(200)
      this.el.removeClass('active')
      this.open = false

      $(document).off('mousedown', this.onDocMouseDown, this)
    },

    onClick (e) {
      if ($(e.target).closest(this.menu).length) {
        return
      }

      if (this.open) {
        this.hideMenu()
      }
      else {
        this.onBeforeMenuShow()
        this.showMenu()

        $(document).on('mousedown', this.onDocMouseDown, this)
      }
      e.preventDefault()
      e.stopPropagation()
    },

    onDocMouseDown (e) {
      if (!$(e.target).closest(this.el).length && !$(e.target).closest(this.menu).length) {
        this.hideMenu()
        // e.preventDefault()
        // e.stopPropagation()
      }
    },

    preventMouseDown (e) {
      e.preventDefault()
    },

    onDestroy () {
      this.menu.remove()
    },
  })

}
/* eslint-disable */
export default function registerRating($, apower) {
  const { tr } = apower

  $.register('rating', {

    selector: '.rating',

    template: '\
		<div class="rating" data-rating="4.5">\
			<div class="rating-count">{@@reviews} ({{this.reviewCount}})</div>\
			<div class="rating-stars">\
				<div class="rating-background-stars">\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
				</div>\
				<div class="rating-current-stars">\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
					<icon type="star"></icon>\
				</div>\
			</div>\
		</div>\
	',

    ref: {
      starWrap: '.rating-stars',
      backgroundEl: '.rating-background-stars',
      currentEl: '.rating-current-stars',
      textEl: '.rating-text',
      valueEl: '[itemprop="ratingValue"]',
      countEl: '[itemprop="ratingCount"]',
      thanksEl: '.rating-thanks',
    },

    rating: 0,

    reviewCount: 0,

    posturl: '',

    selectable: false,

    size: 'normal',	//or small

    autoSet: false,

    init: function () {
      if (this.selectable) {
        this.el.addClass('selectable')
      }

      if (this.size === 'small') {
        this.el.addClass('rating-sml')
      }

      this.ratingLabels = tr('rating_labels').split(';')

      if (this.isOld) {
        this.selectable = this.el.hasClass('selectable')
        this.posturl = this.posturl || this.el.closest('[data-posturl]').data('posturl')

        if (!this.rating) {
          this.rating = Number(this.valueEl.text())
        }

        this.starWrap.append(
          '<div class="rating-background-stars">'+
          '<i></i><i></i><i></i><i></i><i></i>'+
          '</div>'+
          '<div class="rating-current-stars">'+
          '<i class="active"></i><i class="active"></i><i class="active"></i><i class="active"></i><i class="active"></i>'+
          '</div>'
        )

        this.applyRef()
      }

      this.setValue(this.rating)

      var startEls = this.starWrap.find('.icon, i')
      startEls.$on('mouseenter', this.onEnterStar, this)
      startEls.$on('mouseleave', this.onLeaveStar, this)
      startEls.$on('click', this.onClickStar, this)

      this.initArticleRating()
    },


    initArticleRating: function () {
      if (this.posturl) {
        this.on('select', function (rating) {
          this.el.removeClass('selectable').attr('disabled', 'disabled')
          this.selectable = false

          $.ajax({
            type: 'POST',
            url: $.nocache(this.posturl),
            dataType: "json",
            data: {rating: rating},	//this interface is special, rating must be post as formData, so can't set dataType
            success: (function (body) {
              if (body.state == 1) {
                this.countEl.text(( parseInt(this.countEl.text()) || 0 ) + 1)
                this.thanksEl.show()
              }
            }).bind(this)
          })
        }, this)
      }
    },


    setValue: function (star) {
      star = Number(star)
      this.rating = star

      //fix star to make sure it cover star more closer to the middle of each stars
      var fixedStar = star
      var decimalStar = star - Math.floor(star)
      var middleStar = Math.floor(star) + 0.5

      if (decimalStar > 0) {
        fixedStar = middleStar + (star - middleStar) * 22 / 32
      }

      this.currentEl.width(fixedStar * 20 + '%')
      this.textEl.text(this.ratingLabels[Math.floor(star - 0.0001)])	//5 -> 4.9999 to get text
      this.valueEl.text(star)
    },


    onEnterStar: function (e) {
      if (this.selectable) {
        var index = $(e.currentTarget).index()
        var text = this.ratingLabels[index]
        this.textEl.text(text)
        this.backgroundEl.find('i, .icon').slice(0, index + 1).addClass('active')
      }
    },


    onLeaveStar: function (e) {
      if (this.selectable) {
        this.textEl.text(this.ratingLabels[Math.floor(this.rating - 0.0001)])
        this.backgroundEl.find('i, .icon').removeClass('active')
      }
    },


    onClickStar: function (e) {
      if (this.selectable) {
        var index = $(e.currentTarget).index()
        var star = index + 1

        if (this.autoSet) {
          this.setValue(star)
        }

        this.trigger('select', index + 1)
      }
    }
  })

}
/* eslint-disable */
import $, { i18n } from "@apowersoft/jqueryvm";

/*
管理后端植入页面的数据， 域名管理
 */
const apower = {
  lang: document.documentElement.lang,
  pageType: window.apowerConfig?.pageType,
  productId: window.apowerConfig?.productId,
  productType: window.apowerConfig?.productType || "1",
  //针对一些有多语言子域名的网站, 获得多语言子域名
  getRegionPath(lang) {
    let region = this.getShortRegion(lang);
    if (region === "en") {
      return "";
    } else {
      return "/" + region;
    }
  },

  //针对一些有多语言子域名的网站, 获得多语言的 region 标识
  //这个 region 并不是 iso 标准 region 标识, 而是我们一般的网站子域名, 或者软件识别在语言时使用的标识
  getShortRegion(lang) {
    lang = lang || apower.lang;

    let langRegionMappings = {
      cs: "cz",
      da: "dk",
      ja: "jp",
      sv: "se",
      el: "gr",
    };

    let region = langRegionMappings[lang];
    if (!region) {
      region = lang;
    }

    return region;
  },
  getMyAccountDomain() {
    if (this.isDev) {
      return "http://dev050.apowersoft.com";
      //return 'http://myaccount.apowersoft.test'
    } else {
      if (apower.lang === "zh" || location.host.endsWith("cn")) {
        return "https://myaccount.apowersoft.cn";
      } else {
        return "https://myaccount.apowersoft.com";
      }
    }
  },

  getMyAccountMobileDomain() {
    if (this.isDev) {
      return "http://myaccountm.apowersoft.test";
    } else {
      if (apower.lang === "zh" || location.host.endsWith("cn")) {
        return "https://myaccountm.apowersoft.cn";
      } else {
        return "https://myaccountm.apowersoft.com";
      }
    }
  },
  getDomain(lang) {
    if (this.isDev) {
      if (lang === "zh") {
        return "http://apowersoftcn.test";
      } else {
        return "http://apowersoft.test";
      }
    }

    lang = lang || apower.lang;

    let langDomainMappings = {
      pt: ".com.br",
      en: ".com",
      zh: ".cn",
      tr: "-tr.com",
      no: "-no.com",
      cs: ".cz",
      da: ".dk",
      ja: ".jp",
      sv: ".se",
      el: ".gr",
      ar: ".ae",
    };

    let otherDomains = "fr de it nl es fi pl tw hu kr".split(" "); //ru id ar in

    if (!langDomainMappings[lang] && !otherDomains.includes(lang)) {
      lang = "en";
    }

    let dotcom = langDomainMappings[lang] || "." + lang;
    let domain = "https://www.apowersoft" + dotcom;

    return domain;
  },
  getAPIDomain(lang) {
    if (this.isDev) {
      return "http://api.apowersoft.test";
    } else if (apower.lang === "zh") {
      return "https://api.apowersoft.cn";
    } else {
      return "https://api.apowersoft.com";
    }
  },
  endpoint : {
		isDev() {
		  return (
			location.hostname.indexOf("dev-") > -1 ||
			location.hostname.indexOf("devsz-") > -1 ||
			location.hostname.indexOf(".test") == location.hostname.length - 5
		  );
		},
		isOversea() {
		  return apower.lang !== 'zh'
		},
		getHost(){
		  if (this.isDev()) {
			  return this.isOversea()
			  ? `https://devgw.aoscdn.com`
			  : `https://devaw.aoscdn.com`
		  } else {
			return this.isOversea()
				? `https://gw.aoscdn.com`
				: `https://aw.aoscdn.com`
		  }
		},
		getPassportEndpoint(){
		  return this.getPassportBaseEndpoint() + '/v1/api'
		},
		getPassportEndpointV2(){
		  return this.getPassportBaseEndpoint() + '/v2'
		},
		getPassportBaseEndpoint() {
		  // 用户中心: 登录、注册
		  const route = `/base/passport`
		  const chinaEndpoint = `https://awpp.aoscdn.com` + route
		//   const chinaEndpoint = `https://gw.wangxutech.com` + route
		  const endpoint = this.getHost() + route
		  if (this.isDev()) {
			return endpoint
		  } else {
			return this.isOversea() ? endpoint : chinaEndpoint
		  }
		},
		getPaymentEndpoint(){
		  // 支付中心：购买支付
			const route = `/base/payment`
			//微信支付会拒绝awpy,所以改回gw
		  let chinaEndpoint = `https://awpy.aoscdn.com` + route
			const endpoint = this.getHost() + route
			// chinaEndpoint = endpoint
		  if (this.isDev()) {
			return endpoint
		  } else {
			return this.isOversea() ? endpoint : chinaEndpoint
		  }
		},
		getPaymentEndpointV2(){
			// 支付中心：购买支付
			const route = `/base/payment/v2`;
			  //微信支付会拒绝awpy,所以改回gw
			let chinaEndpoint = `https://awpy.aoscdn.com` + route;
			  const endpoint = this.getHost() + route
			  // chinaEndpoint = endpoint
			if (this.isDev()) {
			  return endpoint
			} else {
			  return this.isOversea() ? endpoint : chinaEndpoint
			}
		  },
		getVipEndpoint(){
		  // vip中心：vip状态
		  const route = `/base/vip`
		  const chinaEndpoint = `https://awvp.aoscdn.com` + route
		  const endpoint = this.getHost() + route
		  if (this.isDev()) {
			return endpoint
		  } else {
			return this.isOversea() ? endpoint : chinaEndpoint
		  }
		},
	  },
}
$.extend(apower, i18n)


export default apower
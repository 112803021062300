export default function registerBaiduSearch($, apower) {
  // baidu search
  $.registerDir("baidu-search", {
    selector: "form.search, form.search-blank",

    ref: {
      inputEl: "[type=text]",
      siteEl: "[name=as_sitesearch]",
    },

    url: "https://www.baidu.com/s",

    init: function () {
      this.el.$on(
        "submit",
        function (e) {
          if (apower.lang === "zh") {
            e.preventDefault();
            this.onBaiduSearch();
          }
        },
        this
      );
    },

    onBaiduSearch: function () {
      var val = this.inputEl.val();
      var site = this.siteEl.val();
      var searchValue = val + " site:" + site;

      var form = $(
        '<form action="{0}" target="_blank"><input type="hidden" name="wd" value="{1}"></form>'.format(
          this.url,
          searchValue
        )
      ).appendTo("body");

      form.submit();
      form.remove();
    },
  });
}
